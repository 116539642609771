import React from "react"
import { SEO } from "../components/index"
import PagePreview from "../components/page-preview"

const PreviewTemplate = (props) => {
  return <PagePreview location={props.location} />
}

export default PreviewTemplate

export function Head() {
  return (
    <SEO>
      <meta name="robots" content="noindex, nofollow" />
    </SEO>
  )
}
